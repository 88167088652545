<template>
  <df-modal
    persistent
    :action-name="modalActionName"
    :loading="loading"
    :title="modalTitle"
    @action-click="contactWhatsapp"
    @close="closeExceededUsersModal"
  >
    <template #card-content>
      <p class="exceeded-users-modal">
        {{ $t('Users.modal.exceeded_users_description', [accountMaxUsers]) }}
      </p>
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ExceededUsersModal',

  components: {
    DfModal,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters('user', ['accountMaxUsers']),
    commercialWhatsapp() {
      return (
        process.env.VUE_APP_COMMERCIAL_WHATSAPP +
        this.$t('contato.msg_whatsapp_exceeded_users')
      )
    },
    modalActionName() {
      return this.$t('Users.modal.comercial_whatsapp')
    },
    modalTitle() {
      return this.$t('Users.modal.exceeded_users_title')
    },
  },

  methods: {
    closeExceededUsersModal() {
      this.$emit('close')
    },
    contactWhatsapp() {
      window.open(this.commercialWhatsapp).focus()
      this.closeExceededUsersModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.exceeded-users-modal {
  @include paragraph-large;
}
</style>
